<!--
 * @Author: 月魂
 * @Date: 2021-12-02 18:53:25
 * @LastEditTime: 2022-09-20 16:37:12
 * @LastEditors: 月魂
 * @Description: 日程页面
 * @FilePath: \silk-road\src\views\ScheduleList.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fintro%2Fheader.png" alt="" class="header-img">
      <van-icon name="arrow-left" class="back" @click="handleBack" />
    </div>
    <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Fintro%2Ftable.jpg" alt="" class="table" @click="showImg">
  </div>
</template>

<script>
import Vue from 'vue'
import { ImagePreview, Icon } from 'vant'
import { handleBack } from '../utils/util'
Vue.use(ImagePreview).use(Icon)

export default {
  name: 'ScheduleList',
  mounted() {
    this.wxShareDef();
  },
  methods: {
    handleBack() {
      handleBack()
    },
    showImg() {
      ImagePreview({
        images: [require('../assets/table.jpg')],
        showIndex: false,
        loop: false
      });
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #FCF8ED;
  .header {
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      width: 24px;
      margin: auto;
      color: #fff;
      line-height: 44px;
    }
  }
  .table {
    display: block;
    width: 100%;
  }
}
</style>
